.frontpage {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35rem;

  /* background: linear-gradient(90deg, #f35a12, #d30404, #ff0000, #ff298d); */
  /* background-image: url("./PosiblePortada.jpg"); */
  background-color: #ff00e1;
  background-repeat: repeat;
  background-size: contain;
  opacity: 0.9;
  color: white;
}

.frontpage .logof {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 50%;
  border-left: 1px solid white;
  padding: 1rem;
}

.frontpage .info {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 50%;
  padding: 1rem;
  text-align: center;
}

.frontpage .info p {
  color: white !important;
  text-shadow: 1px 1px 1px rgba(95, 95, 95, 0.603);
}

[data-nombre="Texto"] {
  display: none;
  /* Ocultamos el div cuando la pantalla sea pequeña */
}

@media screen and (max-width: 768px) {
  .logof {
    display: none;
    opacity: 0;
  }

  .info p {
    font-size: 1rem !important;
  }

  .frontpage {
    background: #ff00e1 !important;
  }

  [data-nombre="Texto"] {
    opacity: 1 !important;
  }
}

[data-nombre="Texto"] {
  opacity: 1;
}

@media screen and (min-width: 768px) {}

.portada {
  width: 300px;
}