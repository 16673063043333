body {
  top: 0;
  margin: 0;
}

.Aboutinfo {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  gap: 4rem;
}

.Aboutinfo div {
  width: 90%;
}

.aboutimage img {
  width: 350px;
  float: left;
  margin: 0rem 4rem 4rem 0rem;
}

.aboutimage p {
  text-align: left;
  padding-right: 4rem;
}

.aboutImages {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: center;
  margin-bottom: 5rem;
}

.aboutImages div {
  display: flex;
  width: 100%;
  justify-content: center;
}

.aboutImages img {
  width: 30%;
}

@media screen and (max-width: 768px) {
  .aboutImages {
    flex-direction: column;
  }

  .aboutImages img {
    width: 70%;
  }
}
