.slideContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  width: 98%;
}

.slideContainer div:first-child,
.slideContainer div:last-child {
  width: 1rem;
  height: 20rem;
}

.slideContainer div:first-child {
  display: flex;
  align-items: center;
}

.slideContainer div:last-child {
  display: flex;
  align-items: center;
}

.slideshow {
  display: flex;
  justify-content: center;
  position: relative;
  height: 400px;
  margin: 0 auto;
  overflow: hidden;
  width: 100% !important;
}

.slideshow img {
  top: 0;
  left: 0;
  width: 50%;
  transition: opacity 0.5s ease-in-out;
}

.next-button,
.prev-button {
  background-color: transparent;
  border: none;
  color: black;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 5rem;
}

.prev-button {
  left: 20px;
}

.next-button {
  right: 20px;
}

.indicators {
  display: flex;
  justify-content: center;
  margin-top: 0rem;
  width: 100%;
  height: 3rem;
  background-color: transparent !important;
}

.indicator {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: rgb(177, 176, 176);
  margin: 1rem;
  cursor: pointer;
}

.indicator.active {
  background-color: black;
}

@media screen and (max-width: 768px) {
  .slideContainer {
    margin-top: 0rem;
  }

  .slideshow img {
    width: 100% !important;
  }

  .indicators {
    margin-top: -8rem;
  }
}
