.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 24px;
  background-color: #ff00e1;
  /* background: linear-gradient(90deg, #f35a12, #d30404, #ff0000, #ff298d); */
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
  border: 2px solid white;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top.visible {
  opacity: 1;
}
