.artistDetail {
  width: 100%;
  overflow-wrap: break-word !important;
  /* word-break: break-all !important; */
}

.artistDetail div:first-child {
  display: flex;
  justify-content: center;
  margin-bottom: 10rem;
}

.artistDetail div:first-child img {
  width: 50%;
}

.artistDetail hr {
  margin: 5rem 0rem 5rem 0rem;
  border-radius: 10px;
  height: 0.15rem;
  background-color: #ff00e1;
  /* background: linear-gradient(90deg, #f35a12, #d30404, #ff0000, #ff298d); */
}

.artistDetail h1 {
  margin-bottom: 3rem;
}

.volverAtras {
  padding: 1rem;
  border-radius: 10px;
  text-decoration: none !important;
  border: 1px solid black;
  color: white;
  background-color: #ff00e1;
  /* background: linear-gradient(90deg, #f35a12, #d30404, #ff0000, #ff298d); */
}

.volverAtrasContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 0;
  margin-top: 5rem;
}

.portadaDetail {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 5rem;
}
.portadaDetail img {
  width: 70%;
}

.eslogan {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 !important;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .portadaDetail img {
    width: 100% !important;
  }
}
