.myCard {
  background-color: transparent;
  width: 14rem;
  height: 17rem;
  perspective: 1000px;
}

.title {
  font-size: 1rem;
  font-weight: 900;
  text-align: center;
  margin: 0;
  z-index: -2;
}

.innerCard {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.innerCard img {
  width: 100%;
  height: 12rem;
  z-index: 1;
}

.myCard:hover .innerCard {
  transform: rotateY(180deg);
}

.frontSide,
.backSide {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 1rem;
  color: white;
  box-shadow: 0 0 0.3em rgba(255, 255, 255, 0.5);
  font-weight: 700;
  /* overflow: hidden !important; */
  /* white-space: nowrap !important; */
  /* text-overflow: ellipsis !important; */
}

.backSide p {
  font-size: 0.8rem;
}

/* .frontSide,
  .frontSide::before {
    background: linear-gradient(43deg, rgb(65, 88, 208) 0%, rgb(200, 80, 192) 46%, rgb(255, 204, 112) 100%);
  } */

/* .backSide,
.backSide::before {
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
} */

.backSide {
  transform: rotateY(180deg);
}

.frontSide::before,
.backSide::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 110%;
  height: 110%;
  position: absolute;
  z-index: -2;
  border-radius: 1em;

  animation: animate 5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0.3;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
