.contactLinks {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 4rem;
}

.contactLinks a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.5rem;
}

.contactLinks img {
  width: 70px;
}
