body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f0f0f0 !important;
}

p {
  font-size: 20px; /* Ajusta el tamaño de letra mediana según tus preferencias */
  font-family: Arial, sans-serif; /* Utiliza la fuente Arial */
  color: #3b3b3b; /* Color grisáceo, puedes ajustar el valor según tus preferencias */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); /* Sombra mínima, puedes ajustar los valores según tus preferencias */
}

h1 {
  font-size: 40px; /* Ajusta el tamaño grande según tus preferencias */
  font-family: Arial, sans-serif; /* Utiliza la fuente Arial */
  text-shadow: 4px 3px 2px rgba(0, 0, 0, 0.1); /* Sombra mínima, puedes ajustar los valores según tus preferencias */
}
