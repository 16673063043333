.dropdownContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 5rem;
  z-index: -1;
}

.dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 4rem;
  width: 95%;
  border-radius: 20px;
  border: 1px solid rgba(211, 211, 211, 0.562);
  background: #e0e0e0;
  box-shadow: 35px 35px 58px #d7d7d7, -35px -35px 58px #e9e9e9;
}
.dropdown-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-color: transparent;
  margin-left: 10px;
}

.dropdown-arrow.up {
  border-bottom-color: #000;
  margin-bottom: 0.5rem;
}

.dropdown-arrow.down {
  border-top-color: #000;
}

.dropdown-content {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease, opacity 0.3s ease;
}

.dropdown.active .dropdown-content {
  opacity: 1;
  height: auto;
}
