.carrouselMuseumEvents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60rem;
  width: 100rem;
  overflow: hidden;
  /* background-color: #d30404; */
}
.carrouselMuseumEvents figure {
  position: absolute;
}

.carrouselMuseumEvents figure img {
  height: 50rem;
  width: 40rem;
}

.mainEvent {
  z-index: 3;
}

.prevEvent {
  left: 20rem;
  z-index: 2;
}

.prevEvent img {
  height: 40rem !important;
  width: 30rem !important;
  opacity: 0.4;
}

.nextEvent {
  right: 20rem;
  z-index: 2;
}

.nextEvent img {
  height: 40rem !important;
  width: 30rem !important;
  opacity: 0.4;
}

.EventsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow-x: hidden;
}

.personality {
  font-size: 36px;
  background-color: #ff00e1;
  /* background-image: linear-gradient(90deg, #f35a12, #d30404, #ff0000, #ff298d); */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: rainbow-animation 5s linear infinite;
}
.sectionMuseumEvents {
  display: grid;
  grid-template-columns: auto;
  gap: 3rem;
  width: 100%;
  justify-content: start;
  align-content: center;
  align-items: center;
  margin: 3rem 0rem;
}

.CarrouselFlickity {
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.CarrouselFlickity h1 {
  margin-bottom: 3rem;
}

.flickity-enabled {
  margin-bottom: 4rem;
}

.flickity-enabled figure {
  opacity: 0;
}

.flickity-enabled figure img {
  height: 40rem !important;
  width: 30rem !important;
}

.is-selected {
  opacity: 1 !important;
}

figure figcaption {
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 900;
}

@media screen and (max-width: 768px) {
  .flickity-viewport {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .flickity-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .flickity-enabled figure {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
  }

  .flickity-enabled figure img {
    height: 30rem !important;
    width: 20rem !important;
  }

  .flickity-button {
    display: none;
  }
}
