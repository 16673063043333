.colorSectionContainer {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  width: 100%;
  background-image: url("./colorSection.png");
  background-size: cover;
  padding: 2rem 2rem !important;
  margin: 0 !important;
}

.colorSection {
  width: 90%;
  padding: 1rem;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.548);
  margin: 0 !important;
}
