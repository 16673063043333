.collage-container {
  display: grid;
  grid-template-columns: auto auto auto auto; /* Crea 4 columnas de tamaño igual */
  gap: 5px;
  width: 100%;
  justify-items: center;
  align-items: center;
}

.collage-item {
  height: 400px;
  width: 100%;
}

.collage-description {
  text-align: start !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
  width: 70%;
  height: 10rem;
  /* background-color: blue; */
  overflow: hidden;
}

.collage-vinyl {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3.5rem;
  /* background-color: red; */
}

@media (max-width: 1270px) {
  .collage-container {
    grid-template-columns: auto auto auto;
  }
  .collage-item {
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 1300px) {
  .collage-container {
    grid-template-columns: auto auto;
  }
  .collage-item {
    margin-bottom: 2.5rem;
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 999;
}

@media (max-width: 768px) {
  .collage-container {
    grid-template-columns: auto;
  }
  .collage-item {
    margin-bottom: 1.5rem;
  }
}
