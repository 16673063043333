.sectionContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 5rem;
  overflow-wrap: break-word !important;
  /* word-break: break-all; */
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  gap: 4rem;
  width: 95%;
  border-radius: 20px;
  border: 1px solid rgba(211, 211, 211, 0.562);
  background: #e0e0e0;
  box-shadow: 35px 35px 58px #d7d7d7, -35px -35px 58px #e9e9e9;
  overflow-wrap: break-word !important;
  /* word-break: break-all; */
}

h1 {
  text-align: center;
  font-size: 3rem !important;
}
.ownTest {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 24px !important;
  }
}
