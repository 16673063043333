.artistCard {
  display: flex;
  justify-content: start;
  flex-wrap: nowrap;
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 3rem;
  padding: 2rem;
  gap: 10rem;
  background-color: #ff00e1;
  /*background: linear-gradient(90deg, #f35a12, #d30404, #ff0000, #ff298d);*/
  width: 100%;
}

.artistCard div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.artistCard a {
  text-decoration: none;
}

.artistCard img {
  width: 300px;
  height: 250px;
}

.artistCard div p:first-child {
  font-size: 3rem;
}

.artistCard div p {
  color: white !important;
}

@media screen and (max-width: 1090px) {
  .artistCard {
    justify-content: center;
    flex-wrap: wrap;
  }

  .artistCard div {
    width: 100%;
  }

  .artistCard div p:first-child {
    width: 100%;
    font-size: 2rem;
    text-align: center;
    margin-top: -5rem;
  }

  .artistCard img {
    width: 160px;
    height: 125px;
  }
}
