.vinylsContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.vinylsContainer .personality {
  font-size: 1.5rem !important;
}
