.newsPage {
  word-break: break-all !important;
}

.news img {
  margin-top: 1.5rem;
  height: 400px !important;
}
.news h5 {
  margin-top: 1.5rem;
}

.newsContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.newsContainer .personality {
  font-size: 1.5rem !important;
}
.news ul {
  list-style: none;
  width: 100%;
  background: transparent;
  padding: 30px;
  border-radius: 4px;
}
.news ul li {
  padding: 10px 0 10px 0;
  padding: 2rem !important;
}
.news ul li:nth-child(odd) {
  text-align: left;
  border-left: 10px solid #ff00e1;
  padding-left: 4px;
  border-bottom: 10px solid #f779e8;
}
.news ul li:nth-child(even) {
  text-align: right;
  border-right: 10px solid #ff00e1;
  padding-right: 4px;
  border-bottom: 10px solid #f779e8;
}

.news a {
  color: #ff00e1;
}

.news a:hover {
  color: #f779e8;
}

@media screen and (min-width: 768px) {
  .news ul li {
    padding: 10px 0 10px 0;
    padding: 3rem !important;
  }
}

@media screen and (max-width: 768px) {
  .news img {
    display: none;
  }
}
