.videographyContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
}
.mainVideo {
  width: 50% !important;
  height: 500px !important;
  margin: 0 15rem 0 15rem;
}

.videographyContainer iframe {
  width: 23.8%;
  height: 250px;
}

.videographyContainer:first-child iframe {
  width: 23.8%;
  height: 250px;
}

@media screen and (max-width: 768px) {
  .videographyContainer iframe {
    width: 100% !important;
    height: 250px !important;
  }
}
