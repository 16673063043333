.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0rem 1rem 0rem;
}

.search-bar input[type="text"] {
  padding: 8px;
  border: 1px solid red !important;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 16px;
}

.search-bar button {
  padding: 8px 16px;
  background-color: #ff00e1;
  /* background: linear-gradient(90deg, #f35a12, #d30404, #ff0000, #ff298d); */
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.search-bar button:hover {
  background-color: #ff00e1;
  /* background: linear-gradient(90deg, #f35912b6, #9c0606, #850a0a, #6d0b39); */
}
