footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-content: center;
  bottom: 0;
}

.text-muted {
  color: rgb(156, 156, 156) !important;
}
